import React from 'react';
// import {
//   Twitter,
//   Twitch,
//   Instagram,
//   Facebook,
//   Youtube,
//   GitHub,
// } from 'react-feather';
import * as styles from '../styles/components/Footer.module.css';
import { CookieConsentContext } from './cookie-consent';

// const socialMedia = {
//   TWITTER: {
//     url: "https://twitter.com",
//     name: "Twitter",
//     icon: <Twitter />,
//   },
//   INSTAGRAM: {
//     url: "https://instagram.com",
//     name: "Instagram",
//     icon: <Instagram />,
//   },
//   FACEBOOK: {
//     url: "https://facebook.com",
//     name: "Facebook",
//     icon: <Facebook />,
//   },
//   YOUTUBE: {
//     url: "https://youtube.com",
//     name: "YouTube",
//     icon: <Youtube />,
//   },
//   GITHUB: {
//     url: "https://github.com",
//     name: "GitHub",
//     icon: <GitHub />,
//   },
//   TWITCH: {
//     url: "https://twitch.tv",
//     name: "Twitch",
//     icon: <Twitch />,
//   },
// }

// const getSocialURL = ({ service, username }) => {
//   const domain = socialMedia[service]?.url
//   if (!domain) return false
//   return `${domain}/${username}`
// }

// const getSocialIcon = ({ service }) => {
//   return socialMedia[service]?.icon
// }

// const getSocialName = ({ service }) => {
//   return socialMedia[service]?.name
// }

// interface FooterData {
//   links: { id: string; href: string; text: string }[];
//   meta: { id: string; href: string; text: string }[];
//   copyright: string;
//   socialLinks: { id: string; service: string; username: string }[];
// }

// const data: FooterData = {
//   links: [
//     {
//       id: "0",
//       href: "#!",
//       text: "Products",
//     },
//     {
//       id: "1",
//       href: "#!",
//       text: "Pricing",
//     },
//     {
//       id: "2",
//       href: "#!",
//       text: "About",
//     },
//     {
//       id: "3",
//       href: "#!",
//       text: "Blog",
//     },
//   ],
//   meta: [
//     {
//       id: "0",
//       href: "/terms",
//       text: "Terms",
//     },
//     {
//       id: "1",
//       href: "/privacy",
//       text: "Privacy Policy",
//     },
//   ],
//   socialLinks: [
//     {
//       id: "0",
//       service: "TWITTER",
//       username: "gatsbyjs",
//     },
//     {
//       id: "1",
//       service: "INSTAGRAM",
//       username: "gatsbyjs",
//     },
//     {
//       id: "2",
//       service: "GITHUB",
//       username: "gatsbyjs",
//     },
//     {
//       id: "3",
//       service: "FACEBOOK",
//       username: "gatsbyjs",
//     },
//     {
//       id: "4",
//       service: "YOUTUBE",
//       username: "gatsbyjs",
//     },
//     {
//       id: "5",
//       service: "TWITCH",
//       username: "gatsbyjs",
//     },
//   ],
//   copyright: "© 2022 Gatsby Inc. All rights reserved",
// }

export interface FooterProps {
  siteName: string;
}

export default function Footer({ siteName }: FooterProps) {
  const { showModal } = React.useContext(CookieConsentContext);
  const year = new Date().getFullYear();

  return (
    <footer className={styles.credits}>
      <p>{`© ${year} ${siteName}`}</p>
      <a
        href="#"
        color="text"
        style={{ lineHeight: '1.7' }}
        onClick={(e) => {
          e.preventDefault();
          showModal();
        }}
      >
        Cookievoorkeuren beheren
      </a>
    </footer>
  );
}
