import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import * as styles from '../styles/components/Header.module.css';

export interface HeaderProps {
  title: string;
}

export default function Header({ title }: HeaderProps) {
  const [navActive, setNavActive] = useState(false);
  const { pathname: pagePath } = useLocation();
  const { menuItems } = useStaticQuery(graphql`
    query HeaderQuery {
      wpMenu(slug: {eq: "hoofdmenu"}) {
        menuItems {
          nodes {
            id
            label
            path
          }
        }
      }
    }
  `).wpMenu;

  const menuListItems = menuItems.nodes.map(({ id, label, path }) => (
    <li key={id} className={classNames({
      [styles.currentMenuItem]: path === pagePath,
    })}>
      <Link to={path}>{label}</Link>
    </li>
  ));

  return (
    <header className={styles.sidebar}>
      <div className={styles.blogHeader}>
        <div className={styles.blogTitle}>
          <Link to='/'>{title}</Link>
        </div>
      </div>

      <div
        onClick={() => setNavActive(!navActive)}
        className={classNames(styles.navToggle, {
          [styles.active]: navActive,
        })}
      >
        <p>
          <span className={styles.show}>Menu tonen</span>
          <span className={styles.hide}>Verberg menu</span>
        </p>
        <div className={styles.bars}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </div>

      <div className={styles.blogMenu}>
        <ul>
          {menuListItems}
        </ul>
      </div>

      <div className={styles.mobileMenu} style={{
        display: navActive ? 'block' : 'none',
      }}>
        <ul>
          {menuListItems}
        </ul>
      </div>
    </header>
  );
}
