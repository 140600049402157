import React from 'react';
import Header from './header';
import Content from './content';
import '../styles/main.css';
import '../styles/variables.css';
import * as styles from '../styles/components/Layout.module.css';

interface LayoutProps {
  title: string;
  siteName: string;
  children: JSX.Element;
}

export default function Layout({ children, title, siteName }: LayoutProps) {
  return (
    <div className={styles.wrapper}>
      <Header title={siteName} />
      <Content title={title} siteName={siteName}>
        {children}
      </Content>
    </div>
  )
};
