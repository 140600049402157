import React from 'react';
import Footer from "./footer"
import * as styles from '../styles/components/Content.module.css';

export interface ContentProps {
  title: string;
  siteName: string;
  children: JSX.Element;
}

export default function Content({ title, siteName, children }: ContentProps) {
  return (
    <main className={styles.content}>
      <article className={styles.postInner}>
        <div className={styles.postHeader}>
          <h1 className={styles.postTitle}>{title}</h1>
        </div>

        <div className={styles.postContent}>
          {children}
        </div>
      </article>

      <Footer siteName={siteName} />
    </main>
  );
}
