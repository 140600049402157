import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

interface PageProps {
  data: {
    wp: {
      generalSettings: {
        title: string;
        language: string;
      };
    };
  };
}

const title = 'Pagina niet gevonden';

export default function NotFound({ data }: PageProps) {
  const { title: siteTitle } = data.wp.generalSettings;

  return (
    <Layout title={title} siteTitle={siteTitle}>
      <p>Het lijkt erop dat je een pagina probeert te openen die niet bestaat. Hij is waarschijnlijk verwijderd, verplaatst of heeft nooit bestaan.</p>
    </Layout>
  )
}

export function Head({ data }: PageProps) {
  const { title: siteTitle, language } = data.wp.generalSettings;

  return (
    <>
      <title>{title} - {siteTitle}</title>
      <meta property="og:locale" content={language} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
    </>
  );
}

export const pageQuery = graphql`
  query NotFoundPage {
    wp {
      generalSettings {
        title
        language
      }
    }
  }
`
